import React, { useState, useEffect, useRef } from 'react';
import Headers from "./Headers";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Global.css';
import ReCAPTCHA from "react-google-recaptcha";

function WReport() {
    const [formData, setFormData] = useState({
        summary: '',
        justification: '',
        attachment: null
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [captchaText, setCaptchaText] = useState('');
    const [userInput, setUserInput] = useState('');

    const canvasRef = useRef(null);

    useEffect(() => {
        initializeCaptcha();
    }, []);

    const generateRandomChar = (min, max) => String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

    const generateCaptchaText = () => {
        let captcha = '';
        for (let i = 0; i < 3; i++) {
            captcha += generateRandomChar(65, 90);
            captcha += generateRandomChar(97, 122);
            captcha += generateRandomChar(48, 57);
        }
        return captcha.split('').sort(() => Math.random() - 0.5).join('');
    };

    const drawCaptchaOnCanvas = (ctx, captcha) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)'];
        const letterSpace = 150 / captcha.length;
        for (let i = 0; i < captcha.length; i++) {
            const xInitialSpace = 25;
            ctx.font = '20px Roboto Mono';
            ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
            ctx.fillText(
                captcha[i],
                xInitialSpace + i * letterSpace,
                Math.floor(Math.random() * 16 + 25),
                100
            );
        }
    };

    const initializeCaptcha = () => {
        setUserInput('');
        const newCaptcha = generateCaptchaText();
        setCaptchaText(newCaptcha);
        drawCaptchaOnCanvas(canvasRef.current.getContext('2d'), newCaptcha);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (event) => {
        setFormData({
            ...formData,
            attachment: event.target.files[0]
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userInput !== captchaText) {
            alert('Captcha verification failed!');
            initializeCaptcha();
            return;
        }
        const userData = JSON.parse(localStorage.getItem('user'));

        const professorId = localStorage.getItem('id');
     
        const form = new FormData();
        form.append('summary', formData.summary);
        form.append('justification', formData.justification);
        form.append('attachment', formData.attachment);
        form.append('graduate_id', professorId);

        try {
            const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/weeklyReports', {
                method: 'POST',
                body: form
            });

            const responseData = await response.json();
            if(responseData.status === 1)
            {
                setIsSubmitted(true);
                alert('Submitted Successfully');
                alert('Plagarism Score:'+responseData.pla);
                setErrorMessage(responseData.message);
            }
            else
            {
                setErrorMessage(responseData.message.attachment || 'Error submitting weekly report');
            }
                // alert('Error submitting weekly report');
            
        } catch (error) {
            console.error('Error submitting weekly report:', error);
        }
        // Your form submission logic here
    };
    const [completedTasks, setCompletedTasks] = useState([]);
    const [upcomingTasks, setUpcomingTasks] = useState([]);
    const [totalHoursWorked, setTotalHoursWorked] = useState(0);
  
    useEffect(() => {
      const userData = (localStorage.getItem('id'));
      const professorId = userData;
  
      fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduatecompletedtasks?graduate_id=' + professorId)
        .then(response => response.json())
        .then(data => {
          setCompletedTasks(data);
        })
        .catch(error => console.error('Error fetching completed tasks:', error));
  
      fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduateupcommingtasks?graduate_id=' + professorId)
        .then(response => response.json())
        .then(data => {
          setUpcomingTasks(data);
        })
        .catch(error => console.error('Error fetching upcoming tasks:', error));
  
      fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/totalHoursWorked?graduate_id=' + professorId)
        .then(response => response.json())
        .then(data => {
          setTotalHoursWorked(data.total_hours_worked);
        })
        .catch(error => console.error('Error fetching total hours worked:', error));
    }, []);
  
    return (
        <>
            <Headers />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h4>Task Summary</h4>
                        <div className="sd-card3">
          <label className="sd-lab1">
            Assigned:  
            <span> {upcomingTasks.length + completedTasks.length}</span>
                      </label>
          <label className="sd-lab1">
            Completed:  
            {/* <textarea className="sd-TA1" maxLength={2} value={2}></textarea> */}
            <span> { completedTasks.length}</span>
          
          </label>
          <label className="sd-lab1">
            Pending:  
            {/* <textarea className="sd-TA1" maxLength={maxLengthValue} value={textareaValue}></textarea> */}
            <span> {upcomingTasks.length }</span>
          
          </label>
        </div>
                    </div>
                    <div className="col-md-6">
                        <h4>Weekly Reports</h4>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Summary</label>
                                <textarea className="form-control" value={formData.summary} name="summary" onChange={handleChange}></textarea>
                            </div>
                            <div className="form-group">
                                <label>Justification</label>
                                <textarea className="form-control" value={formData.justification} name="justification" onChange={handleChange}></textarea>
                            </div>
                            <div className="form-group">
                                <label>Attachment</label>
                                <input type="file" className="form-control-file" onChange={handleFileChange}></input>
                            </div>
                            <div className="captcha-container">
                                <canvas ref={canvasRef} width="200" height="70"></canvas>
                                <button type="button" onClick={initializeCaptcha} className="btn btn-secondary">
                                    Reload Captcha
                                </button>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Enter Captcha" value={userInput} onChange={(e) => setUserInput(e.target.value)} />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit Report</button>
                        </form>
                        {isSubmitted && (
                            <p>Report submitted successfully!</p>
                        )}
                        {errorMessage && (
                            <p className="error-message">{errorMessage}</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default WReport;
