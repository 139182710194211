import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
//import './ContactUs.css';
import './Global.css';
import Header from './loggedOUTheader';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recaptcha, setRecaptcha] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if(isVerified){
      const serviceId = 'YOUR_SERVICE_ID';
      const templateId = 'YOUR_TEMPLATE_ID';
      const publicKey = 'YOUR_PUBLIC_KEY';

      emailjs.sendForm(serviceId, templateId, e.target, publicKey)
        .then((result) => {
          console.log(result.text);
          setSent(true);
        }, (error) => {
          console.log(error.text);
          setError(error.text);
        });
    }
  }

  const onChange = (value) => {
    setRecaptcha(value);
    setIsVerified(true);
  }

  return (
    <div>
        <Header />
        <div className="contact-us-container">
        <script src="https://cdn.emailjs.com/sdk/2.6.4/email.min.js"></script>
        <script src="https://www.google.com/recaptcha/enterprise.js?render=6Ld5yIQpAAAAAH2pf-3FUw09DljLJQgbJSmBv9aC"></script>
        
      <h1>Contact Us</h1>
      <form onSubmit={sendEmail}>
        <label htmlFor="name">Name:</label>
        <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} required />

        <label htmlFor="email">Email:</label>
        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

        <label htmlFor="message">Message:</label>
        <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>

        <ReCAPTCHA
          render="checkbox"
          sitekey="6Ld5yIQpAAAAAH2pf-3FUw09DljLJQgbJSmBv9aC"
          onChange={onChange}
        />

        <button type="submit" disabled={!isVerified}>Submit</button>
      </form>
      {error && <p>{error}</p>}
      {sent && <p>Your message has been sent!</p>}
    </div>
    </div>
  );
}

export default ContactUs;