import React, { useState, useEffect } from 'react';
import { Button, Card, Select, Table, Modal } from 'antd';
import Header from './loggedINheader';

const { Option } = Select;

const RecommendationLetter = () => {
  const [users, setUsers] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [letterModalVisible, setLetterModalVisible] = useState(false);
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchTasks();
    fetchLetters();
  }, []);
  const fetchLetters = async () => {
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/letter.php');
      if (!response.ok) {
        throw new Error('Failed to fetch letters');
      }
      const data = await response.json();
      setLetters(data); // Assuming the response is an array of letters
    } catch (error) {
      console.error('Error fetching letters:', error);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/allUsers');
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch(`https://wdm.gxy5341.uta.cloud/laravel/public/api/getprofessortasks?professor_id=${localStorage.getItem('id')}`);
      if (!response.ok) {
        throw new Error('Failed to fetch tasks');
      }
      const data = await response.json();
      setTasks(data.tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const generateLetter = async () => {
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/letter.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ student_id: selectedStudent,professor_id:localStorage.getItem('id') }),
      });
      if (!response.ok) {
        throw new Error('Failed to generate letter');
      }
      const data = await response.json();
      setLetters(data); // Assuming the response is an array of letters
      setLetterModalVisible(true);
    } catch (error) {
      console.error('Error generating letter:', error);
    }
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const columns = [
    {
      title: 'Task',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Graduate',
      dataIndex: 'assigned_to_name',
      key: 'assigned_to_name',
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <div>
      <Header isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
      <div className='container'>
        <Card title="Student List">
          <Select
            style={{ width: '20%' }}
            onChange={(value) => setSelectedStudent(value)}
            placeholder="Select a student"
          >
            {users.map(user => (
              <Option key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</Option>
            ))}
          </Select>
          <Button onClick={generateLetter}>Generate Letter</Button>
        </Card>
        <Card title={selectedStudent ? `Tasks - ${selectedStudent}` : ''}>
  {letters.map((letter, index) => {
    // Find the user with the matching ID
    const user = users.find(user => user.id === parseInt(letter.student_id));
    // Check if user is found
    if (user) {
      // Display the student name
      const studentName = `${user.firstName} ${user.lastName}`;
      return (
        <div key={index}>
          <h3>Letter {index + 1}</h3>
          <p>Student Name: {studentName}</p>
          <p><a href={letter.letter} download>Download</a></p> {/* Assuming the letter content is available in the 'letter' field */}
        </div>
      );
    } else {
      return (
        <div key={index}>
          <h3>Letter {index + 1}</h3>
          <p>Student Name: Not Found</p>
          <p>{letter.letter}</p> {/* Assuming the letter content is available in the 'letter' field */}
        </div>
      );
    }
  })}
</Card>

      </div>
      <Modal
        title="Generated Letters"
        visible={letterModalVisible}
        onCancel={() => setLetterModalVisible(false)}
        footer={null}
      >
      </Modal>
    </div>
  );
};

export default RecommendationLetter;
