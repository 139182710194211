import Calendar from "./Calendar.js";
import Headers from "./Headers.js";
import ProgressBar from "./Progressbar.js";
//import "./student-dashboard.css";
import './Global.css';
import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function StudentDashboard() {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // fetchUsers();
    fetchtasks();
  }, []);
  let maxLengthValue = 100;
let textareaValue = ''; // Initialize textareaValue with an empty string
// Define textareaValue to display dynamic content (e.g., task title)
  const fetchtasks = async () => 
  {
    
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduateupcommingtasks?graduate_id='+localStorage.getItem('id'));
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setTasks(data);
      // const data = await response.json();
      // setTasks(data.tasks);
      const taskss = data;
      maxLengthValue = 100;
      textareaValue = taskss.length > 0 ? taskss[0].title : ''; // Update textareaValue based on tasks
    
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }
  const [completedTasks, setCompletedTasks] = useState([]);
  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [totalHoursWorked, setTotalHoursWorked] = useState(0);

  useEffect(() => {
    const userData = (localStorage.getItem('id'));
    const professorId = userData;

    fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduatecompletedtasks?graduate_id=' + professorId)
      .then(response => response.json())
      .then(data => {
        setCompletedTasks(data);
      })
      .catch(error => console.error('Error fetching completed tasks:', error));

    fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduateupcommingtasks?graduate_id=' + professorId)
      .then(response => response.json())
      .then(data => {
        setUpcomingTasks(data);
      })
      .catch(error => console.error('Error fetching upcoming tasks:', error));

    fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/totalHoursWorked?graduate_id=' + professorId)
      .then(response => response.json())
      .then(data => {
        setTotalHoursWorked(data.total_hours_worked);
      })
      .catch(error => console.error('Error fetching total hours worked:', error));
  }, []);

  return (
    <>
      {/* <div className="sd-container"> */}
        <Headers />
      {/* </div> */}
      <div className="container">
      <div className="sd-card1">
        <h4 className="sd-h4">Progress</h4>
        <ProgressBar bgcolor="green" completed={Math.round((completedTasks.length*100)/(completedTasks.length+upcomingTasks.length  ),2)} />
      </div>
      <div className="sd-card2">
        <h4 className="sd-h4">Task Summary</h4>
        <div className="sd-card3">
          <label className="sd-lab1">
            Assigned:  
            <span> {upcomingTasks.length + completedTasks.length}</span>
                      </label>
          <label className="sd-lab1">
            Completed:  
            {/* <textarea className="sd-TA1" maxLength={2} value={2}></textarea> */}
            <span> { completedTasks.length}</span>
          
          </label>
          <label className="sd-lab1">
            Pending:  
            {/* <textarea className="sd-TA1" maxLength={maxLengthValue} value={textareaValue}></textarea> */}
            <span> {upcomingTasks.length }</span>
          
          </label>
        </div>
      </div>
      <div className="sd-card4">
        <h4 className="sd-h4">Week Summary</h4>
        <div className="sd-card5">
          <label className="sd-lab1">
            Hours Worked:
            {/* <textarea className="sd-TA1" maxLength={2} value={17}></textarea> */}
            <span> {totalHoursWorked?totalHoursWorked:0}</span>
          </label>
          <label className="sd-lab1">
            Number of deadlines this week:  
            <span> {upcomingTasks.length}
            </span>
            {/* <textarea className="sd-TA1" maxLength={2} value={1}></textarea> */}
          </label>
        </div>
      </div>
      <div>
        {/* <h3 className="sd-h4">Calender</h3> */}
        {/* <h3 className="sd-h3">March</h3> */}
        <Calendar></Calendar>
      </div>
      </div>
    </>

  );
}

export default StudentDashboard;
