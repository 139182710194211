import { Button } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
//import "./header.css";
import './Global.css';

const Header = ({ isLoggedIn }) => {
  return (
    <header>
      <div className="logo">
        <img src="..\Designer.png" alt="Logo" />
      </div>
      <div className="actions">
      <NavLink to="/professor-dashboard">
        
        <Button>Profile</Button>
        </NavLink>
        <NavLink to="/students">
          <Button>Students</Button>
        </NavLink>
        <NavLink to="/letter">
          <Button>Recommendation Letter</Button>
        </NavLink>
        <NavLink to="/chat">
          <Button>Chat</Button>
        </NavLink>
        <NavLink to="/Home">
          <Button>Logout</Button>
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
