import React, { useState } from "react";
import './Global.css';
import Header from "./loggedOUTheader";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    utaID: "",
    phone: "",
    password: "",
    confirmPassword: "",
    role: "Student"
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    // Validation for each field
    switch (name) {
        case "firstName":
        case "lastName":
            if (!value.trim()) {
                errorMessage = "Please enter your first and last name";
            }
            break;
        case "email":
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                errorMessage = "Please enter a valid email address";
            } else if (!value.endsWith('@mavs.uta.edu')) {
                errorMessage = "Please use an email address with @mavs.uta.edu domain";
            }
            break;
        case "utaID":
        case "phone":
            if (!value.trim() || isNaN(value)) {
                errorMessage = "Please enter a valid number";
            }
            break;
        case "password":
            if (value.length < 8) {
                errorMessage = "Password must be at least 8 characters long";
            }
            break;
        case "confirmPassword":
            if (value !== formData.password) {
                errorMessage = "Passwords do not match";
            }
            break;
        default:
            break;
    }

    setFormData({
        ...formData,
        [name]: value,
        // errorMessage: errorMessage,
    });
};

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let errorMessage = "";
    var errorMessage1='';
    let isValid = true; // Flag to track if form is valid
    Object.entries(formData).forEach(([key, value]) => {
        if (!value) {
             errorMessage1 += `Please enter ${key}\n`;
            alert(errorMessage1);
        
            isValid = false;
            return; // Set flag to false if any field is empty
        }
    });
  
    if (!isValid) {
        alert(errorMessage1);
        return; // Exit function if any field is empty
    }
  
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
        var mes = "Please enter a valid email address";
        alert(mes);
        isValid=false;
        return;
    }
    const emailRegex2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex2.test(formData.email)) {
        errorMessage = "Please enter a valid email address";
        alert(errorMessage);
        isValid=false;
        return;
    } 
    
    if (!formData.email.endsWith('@mavs.uta.edu')) {
        errorMessage = "Please use an email address with @mavs.uta.edu domain";
        isValid=false;
        alert(errorMessage);
    }
  
    // Validate phone number
    const phoneRegex = /^\d{10}$/;
    if (formData.phone && !phoneRegex.test(formData.phone)) {
        var mes = "Please enter a valid 10-digit phone number";
        alert(mes);
        isValid=false;
        return;
    }
  
    // Validate password
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (formData.password && !passwordRegex.test(formData.password)) {
        var mes = "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number";
        alert(mes);
        isValid=false;
        return;
    }
    if (formData.password !== formData.confirmPassword) {
      errorMessage = "Passwords do not match";
      alert(errorMessage);
        isValid=false;
        return;
  }

    try {
      if (isValid) {
        const response = await fetch("https://wdm.gxy5341.uta.cloud/laravel/public/api/registerGraduate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
            throw new Error("Error registering user");
        }
  
        const data = await response.json();
        if (data.status === 1) {
            alert("User registered successfully");
            localStorage.setItem('verifyotp', formData.email);
            navigate('/verifyotp');
        } else {
            alert(data.message);
        }
        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            utaID: "",
            phone: "",
            password: "",
            confirmPassword: "",
            role: "Student"
        });
      }

    } catch (error) {
        console.error("Registration failed:", error.message);
    }
};


  return (
    <div>
      <Header />
      <div className="registration-container">
        <h2>Registration Form</h2>
        <form onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
             {formData.errorMessage && (
               <p className="error-message">{formData.errorMessage}</p>
             )}
          </div>
          <div className="form-group">
            <label htmlFor="email">UTA ID</label>
            <input
              type="number"
              id="utaID"
              name="utaID"
              value={formData.utaID}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Phone</label>
            <input
              type="number"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn">
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
