//import "./Service.css";
import './Global.css';
import Header from "./loggedOUTheader";

function Service(){
    return(
        <>
        <Header />
        <div className = "ser-cards">
            <h1 className = "ser-h">Services</h1>
            <h3>Task Assignment Platform</h3>
            <p className = "ser-pa">Create a platform where supervisors can post available tasks or projects for volunteers to choose from. Volunteers can then select tasks that align with their interests and expertise.</p>
            <h3>Time Tracking and Reporting Tools</h3>
            <p className = "ser-pa">Develop tools for volunteers to track their volunteer hours and submit weekly reports on their activities and accomplishments. Supervisors should also have access to these tools to review and approve volunteer reports.</p>
            <h3>Communication Channels</h3>
            <p className = "ser-pa">Set up communication channels such as messaging systems or discussion forums within the application to facilitate communication between volunteers, supervisors, and program administrators. This ensures effective coordination and collaboration among participants.</p>
            <h3>Feedback and Evaluation Mechanism</h3>
            <p className = "ser-pa">Implement mechanisms for collecting feedback from both volunteers and supervisors to evaluate the effectiveness of the program. This feedback can be used to make improvements and adjustments as needed.</p>
            <h3>Documentation and Compliance</h3>
            <p className = "ser-pa">Provide resources and guidelines for volunteers and supervisors regarding program requirements, immigration regulations, and reporting procedures. Ensure that all documentation and processes comply with legal and institutional guidelines.</p>
            <h3>Job Placement Assistance</h3>
            <p className = "ser-pa">Offer resources and support for volunteers who are actively seeking employment. This could include resume building workshops, interview preparation sessions, and networking opportunities within the industry.</p>
            <h3>Training and Skill Development</h3>
            <p className = "ser-pa">Provide access to training materials, workshops, or online courses to help volunteers enhance their skills and knowledge in relevant areas of Computer Science</p>
            <h3>Community Building Activities</h3>
            <p className = "ser-pa">Organize events, webinars, or networking sessions to foster a sense of community among volunteers and encourage peer learning and collaboration.</p>
        </div>
        </>
    );
}
export default Service;