import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './loggedINheader';

function ChatComponent() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        const receiver = localStorage.getItem('id');

        if (receiver) {
            // Fetch messages for the logged-in user
            fetchMessages(receiver);
        }

        // Fetch the list of users
        axios.get('https://wdm.gxy5341.uta.cloud/laravel/public/api/allusers')
            .then(response => setUsers(response.data.users))
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    useEffect(() => {
        const fetchMessagesOnce = () => {
            if (selectedUser) {
                fetchMessages(selectedUser, localStorage.getItem('id'));
                fetchMessages(localStorage.getItem('id'), selectedUser);
            }
        };
    
        // Fetch messages once when selectedUser changes
        fetchMessagesOnce();
    
        // Set interval to fetch messages every 10 seconds
        const interval = setInterval(fetchMessagesOnce, 5000);
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, [selectedUser]);
    

    const fetchMessages = (sender, receiver) => {
        axios.get(`https://dxr5340.uta.cloud/backend/message.php?method=getmessage&sender_id=${sender}&receiver_id=${receiver}`)
            .then(response => {
                const senderToReceiverMessages = Array.isArray(response.data)
                    ? response.data.map(message => ({ ...message, timestamp: new Date(message.timestamp) }))
                    : [];

                axios.get(`https://dxr5340.uta.cloud/backend/message.php?method=getmessage&sender_id=${sender}&receiver_id=${receiver}`)
                    .then(response2 => {
                        const receiverToSenderMessages = Array.isArray(response2.data)
                            ? response2.data.map(message => ({ ...message, timestamp: new Date(message.timestamp) }))
                            : [];

                        // Combine messages from both directions
                        const combinedMessages = [ ...receiverToSenderMessages];

                        // Sort messages by timestamp
                        const sortedMessages = combinedMessages.sort((a, b) => a.timestamp - b.timestamp);

                        setMessages(sortedMessages);
                    })
                    .catch(error => console.error('Error fetching messages:', error));
            })
            .catch(error => console.error('Error fetching messages:', error));
    };
     
    const sendMessage = () => {
        const sender = localStorage.getItem('id');
        axios.post('https://dxr5340.uta.cloud/backend/message.php?method=insert', {
            sender_id: localStorage.getItem('id'),
            receiver_id: selectedUser,
            message: newMessage,
        })
        .then(response => {
            if (response) {
                fetchMessages(selectedUser, sender);
                setNewMessage('');
            } else {
                console.error('Failed to send message');
            }
        })
        .catch(error => console.error('Error sending message:', error));
    };

    return (
        <div>    
            <Header/>    <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <h2>User List</h2>
                    <ul className="list-group">
                        {users.map(user => (
                            <li key={user.id} className={`list-group-item ${user.id === selectedUser ? 'active' : ''}`} onClick={() => setSelectedUser(user.id)}>
                                {user.firstName}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-md-8">
                    <h2>Chat Box</h2>
                    <div className="messages-container">
                    {messages.map((msg, index) => {
    const isSentByCurrentUser = msg.sender_id === parseInt(localStorage.getItem('id'));
    const senderUser = isSentByCurrentUser ? null : users.find(user => user.id === parseInt(msg.sender_id));
    const senderName = isSentByCurrentUser ? 'You' : senderUser.firstName;
                        console.log(isSentByCurrentUser);
    return (
        <div key={index} className={`message ${isSentByCurrentUser ? 'sent' : 'received'}`}>
            <strong>{senderName}:</strong> {msg.message}
        </div>
    );
})}


                    </div>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type your message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={sendMessage} disabled={!selectedUser || !newMessage.trim()}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
}

export default ChatComponent;
