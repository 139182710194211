import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Header from './Header';
import './Global.css';
import Header from './loggedOUTheader';

function VerifyOTP() {
  const [otp, setOTP] = useState('');
  const email = localStorage.getItem('verifyotp'); // Retrieve email from localStorage
  const navigate = useNavigate();

  const handleVerifyOTP = async () => {
    try {
      const response = await fetch(`https://wdm.gxy5341.uta.cloud/laravel/public/api/verifyOTP?email=${email}&otp=${otp}`);
  
      if (response.ok) {
        const data = await response.json();
        if (data.status === 1) {
          alert(data.message); 
          navigate('/login');
        } else {
          alert(data.message); 
        }
      } else {
        const error = await response.json();
        alert(error.message); 
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred');
    }
  };
  
  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  return (
    <>
      <Header />
      <div className="container" style={{ backgroundImage: "url(https://www.freepik.com/free-vector/hand-drawn-back-school-background_28480371.htm#query=education%20background&position=2&from_view=keyword&track=ais&uuid=17947a86-b98d-44b8-a08c-56bd11b65262)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
      <div className="login-container">
        <div className="login-content" style={{maxWidth:"100%"}}>
          <h2>Verify OTP</h2>
          <div className="p-field">
             <div className="box-title">
                <h6 className="text-align-center login_box_title">Verify OTP for {email}</h6>
              </div>
              <div>
                <input type="text" value={otp} onChange={handleOTPChange} />
                <button  className="btn btn-success" onClick={handleVerifyOTP}>Verify OTP</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyOTP;
