import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Home from "./components/Home";
import Service from "./components/Service";
import Signup from "./components/Signup";
import TaskManag from "./components/TaskManag";
import WReport from "./components/WReport";
import LoginPage from "./components/loginPage";
import ProfessorDashboard from "./components/professor-dashboard";
import StudentDashboard from "./components/student-dashboard";
import ChatComponent from "./components/Chat";
import VerifyOTP from "./components/verifyotp";
import Students from "./components/Students";
import RecommendationLetter from "./components/RecommendationLetter";
import RecommendationLetters from "./components/RecommendationLetters";
import Calendar from "./components/Calendar";
import Calendars from "./components/Calendars";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Professor-dashboard" element={<ProfessorDashboard />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/TaskManag" element={<TaskManag />} />
        <Route path="/wreport" element={<WReport />} />
        <Route path="/chat" element={<ChatComponent />} />

        <Route path="/Student-dashboard" element={<StudentDashboard />} />
        <Route path="/AboutUs" element={<About />} />        <Route path="/verifyotp" element={<VerifyOTP />} />

        <Route path="/Services" element={<Service />} />
        <Route path="/students" element={<Students />} />
        <Route path="/letter" element={<RecommendationLetter />} />
        <Route path="/letters" element={<RecommendationLetters />} />
        <Route path="/calendar" element={<Calendars />} />
    
      </Routes>
    </Router>
  );
}

export default App;
