import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Modal, Rate, Select, Table } from 'antd';
import React, { useState,useEffect } from 'react';
import './Global.css';
import Header from './loggedINheader';
import 'bootstrap/dist/css/bootstrap.min.css';

const { Option } = Select;

const Students = () => {
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetchUsers();
   
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState(null);
  
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };
  const handleDelete = async (record) => {
    try {
      // Make DELETE request to delete the user
      const response = await fetch(`https://wdm.gxy5341.uta.cloud/laravel/public/api/deleteUser?user_id=${record.key}`, {
        method: 'GET',
      });
      fetchUsers();
      if (!response.ok) {
        throw new Error('Failed to delete user');
      }
  
      // Remove the deleted user from the state
      setUsers(prevUsers => prevUsers.filter(user => user.id !== parseInt(record.key)));
      alert('Student Deleted');
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/allUsers');
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
//   const handleDelete = (record) => {
//     alert('Student Deleted');
//   };
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="danger" onClick={() => handleDelete(record)}>Delete</Button>
      ),
    },
  ];
  
  
 
  const convertedData = users.map(user => ({
    key: user.id.toString(),
    firstName: user.firstName,
    lastName: user.lastName,
    phone:user.phone,
    email:user.email,
    status: user.completed === 0 ? "In Progress" : "Completed"
  }));
  
 
 
  return (
    <div>
    <Header isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
    <div className='container'>
      
      <Card
        title={selectedStudent ? `Tasks - ${selectedStudent}` : ''}
        extra={
          selectedStudent ? (
            <Button>
              {/* <MailOutlined /> Generate Recommendation Letter */}
            </Button>
          ) : (
            ''
          )
        }
      >
        <Table columns={columns} dataSource={convertedData} />
      </Card>
      </div>
    </div>
  );
};

export default Students;