//import "./About.css";
import "./Global.css";
import Header from "./loggedOUTheader";
function About() {
  return (
    <>
      <Header />
      <div className="ab-caard">
        <h1 className="ab-h1">About US</h1>
        <h4 className="ab-h3">Our Mission</h4>
        <p className="ab-p">
          Grad Impact is committed to empowering graduate students around the
          globe by making materials accessible, encouraging community
          involvement, and creating chances for professional development and
          success in the classroom and beyond. Our goal is to provide a complete
          platform that fosters learning, teamwork, and personal growth to
          assist students at every step of their graduate education journey—from
          application to graduation. By supporting diversity and inclusion,
          standing up for student rights, and providing students with the tools
          they need to succeed in their chosen disciplines, we hope to
          positively influence the graduate education scene.
        </p>
        <h4 className="ab-h3">Our Vision</h4>
        <p className="ab-p">
          Grad Impact sees a time when all graduate students have access to
          opportunities, resources, and support to succeed in their academic and
          professional objectives. Our goal is to establish a global community
          of highly capable graduate students who have the networks, expertise,
          and resources needed to significantly impact both their disciplines
          and society at large. With the help of our platform, we hope to
          promote an inclusive, innovative, and collaborative atmosphere where
          all students feel encouraged, supported, and equipped to realize their
          greatest potential. In the end, we want Grad Impacter to be a driving
          force behind improvements in graduate education, molding the minds and
          leaders of the future and promoting knowledge and advancement for the
          benefit of society.
        </p>
        <h4 className="ab-h3">Cutting Edge Technology of Grad Impact</h4>
        <p className="ab-p">
          Grad Impacter is committed to offering an extensive array of services
          that are customized to meet the requirements of graduate students
          across the globe. Students have access to a wealth of information and
          resources on a variety of graduate education-related topics, such as
          career counseling, finance options, and application advice, through
          our platform. With the help of our dynamic online Community Building
          programs, graduate students can interact with one another, exchange
          experiences, offer guidance, and encourage one another as they pursue
          their academic goals. Furthermore, students are matched through our
          Mentorship Programs with seasoned professionals or senior peers who
          offer tailored advice and assistance. To help graduate students
          flourish both academically and professionally, we also provide
          resources for professional development, research collaboration
          opportunities, and workshops on skill development.
        </p>
        <h4 className="ab-h3">Core Values</h4>
        <p className="ab-p">
          Our core values guide everything we do. We prioritize integrity,
          fostering a culture of honesty, transparency, and accountability in
          all our interactions. We are committed to excellence, continuously
          striving for the highest standards of quality and innovation in our
          services. We value inclusivity, embracing diversity and ensuring that
          all voices are heard and respected. Lastly, we prioritize community,
          fostering a supportive and collaborative environment where graduate
          students can thrive and succeed together.
        </p>
      </div>
    </>
  );
}
export default About;
