import profilelogo from "../Designer.png";
//import "./Home.css";
import './Global.css';
import image2 from "./images.jpeg";
import Header from "./loggedOUTheader";
function Home(){
    return(
        <>
        <Header />
        <div className = "home-card1">
          <h1 className = "home-h1">Impact your career</h1>
          <div className="home-container">
            <p className="home-p1" style = {{textAlign:'justify'}}>Our career impacter is here enhance your hard-work, and to place you in th top position in your current field. This career impacter will help you to be productive after your graduation, and help you to secure a job in your dream company with a high package. So, grab the opportunity and accelerate your career with ous. And don’t forget to sign to our program. For more information about our impacter you can contact us at any time. </p>
            <img src={profilelogo} alt="Grad Impact" className="home-image" />
          </div>
          <div className="home-container">
          <img src={image2} alt="Grad Impact" className="home-image" />
          <div className="home-p1">
            <h2>Career Assessment</h2>
            <p style = {{textAlign:'justify'}}>This platform helps you to be productive throughout your journey of getting you a job in the market. The assessment involves the task assignment of the cutting-edge technologies in the various fields. And each task is then verified by the task assigner. You can check your progress throughout your journey.  </p>
            <ul>
                <li>Task Assessment</li>
                <li>Weekly Submissions</li>
                <li>AI Report Analyzer</li>
            </ul>
            </div>
            </div>
        </div>
        </>
    );
}
export default Home;