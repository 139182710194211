import Headers from "./Headers";
import ProgressBar from "./Progressbar.js";
import React, { useState, useEffect } from 'react';

function TaskManag() {
  const [tasks, setTasks] = useState([]);

  async function fetchtasks() {
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduatetasks?graduate_id=' + localStorage.getItem('id'));
      if (!response.ok) {
        throw new Error('Failed to fetch tasks');
      }
      const data = await response.json();
      setTasks(data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  }

  useEffect(() => {
    fetchtasks();
  }, []);

  function handleComplete(event,id) {
    event.preventDefault();
    
    // Get the checkbox and textarea elements
    const checkbox = document.getElementById('complete');
    const textarea = event.target.closest('tr').querySelector('.tm-TA2');
  
    // Check if the checkbox is checked and the textarea value is not empty
    if (checkbox.checked && textarea.value.trim() !== '') {
      fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/markComplete', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: id, comments: textarea.value.trim() })
      })
        .then(response => {
          alert('Task status updated successfully.');
          // You may want to update tasks here based on the response, if needed
        })
        .catch(error => {
          console.error('Error updating task status:', error);
        });
    } else {
      // Display an error message if the checkbox is not checked or the textarea value is empty
      alert('Please select the checkbox and add comments before marking as complete.');
    }
  }
  const [completedTasks, setCompletedTasks] = useState([]);
  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [totalHoursWorked, setTotalHoursWorked] = useState(0);

  useEffect(() => {
    const userData = (localStorage.getItem('id'));
    const professorId = userData;

    fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduatecompletedtasks?graduate_id=' + professorId)
      .then(response => response.json())
      .then(data => {
        setCompletedTasks(data);
      })
      .catch(error => console.error('Error fetching completed tasks:', error));

    fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduateupcommingtasks?graduate_id=' + professorId)
      .then(response => response.json())
      .then(data => {
        setUpcomingTasks(data);
      })
      .catch(error => console.error('Error fetching upcoming tasks:', error));

    fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/totalHoursWorked?graduate_id=' + professorId)
      .then(response => response.json())
      .then(data => {
        setTotalHoursWorked(data.total_hours_worked);
      })
      .catch(error => console.error('Error fetching total hours worked:', error));
  }, []);

  return (
    <div className="tm-App">
      <Headers />
      <div className="tm-card1">
        <h4 className="tm-h4">Progress</h4>
        {/* <ProgressBar bgcolor="grey" completed={60} /> */}
        <ProgressBar bgcolor="green" completed={Math.round((completedTasks.length*100)/(completedTasks.length+upcomingTasks.length  ),2)} />

      </div>
      <div className="tm-ard2">
        <h4 className="tm-h4">Task Summary</h4>
        <div className="tm-card3">
          <label className="tm-lab1">
            Assigned: &ensp; 
              {tasks.length}
          </label>
          {/* Similar labels for Completed and Pending tasks */}
        </div>
      </div>
      <div className="tm-card4">
        <h2 className="tm-h4">Task Management</h2>
        <div className="tm-container">
          <table className="tm-table">
            <thead>
              <tr>
                <th>Name of Task</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Comments</th>
                <th>View Task</th>
              </tr>
            </thead>
            <tbody>
            {tasks.map((val, key) => (
  <tr key={key}>
    <td>{val.title}</td>
    <td>{val.created_at.split('T')[0]}</td>
    <td>{val.deadline}</td>
    <td>{val.priority}</td>
    <td>
      {val.completed === 1 ? (
        <input type="checkbox" id="complete" checked disabled />
      ) : (
        <input type="checkbox" id="complete" />
      )}
    </td>
    <td>
      {val.completed === 1  ? (
        <div>{val.comments}</div>
      ) : (
        <textarea className="tm-TA2" placeholder="Add Comment"></textarea>
      )}
    </td>
    <td>
      {val.completed === 1 ? (
        <button className="tm-button1" disabled>Submitted</button>
      ) : (
        <button className="tm-button1" onClick={(event) => handleComplete(event, val.id)}>Mark As Complete</button>
      )}
    </td>
  </tr>
))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TaskManag;
