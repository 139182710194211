import { Button } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
//import "./Headers.css";
import './Global.css';

const Headers = ({ isLoggedIn }) => {
  return (
    <header>
      <div className="logo">
        <img src="..\Designer.png" alt="Logo" />
        {/* <h2>Student Dashboard</h2> */}
      </div>

      <h2>Student Dashboard</h2>
      <div className="actions">
        <NavLink to="/TaskManag">
          <Button>Tasks</Button>
        </NavLink><NavLink to="/calendar">
          <Button>Calendar</Button>
        </NavLink>
        
        <NavLink to="/wreport">
          <Button>Submit Week Report</Button>
        </NavLink> <NavLink to="/student-dashboard">
          <Button>Profile</Button>
        </NavLink>
        <NavLink to="/chat">
          <Button>Chat</Button>
        </NavLink>
        {/* <Button>Profile</Button> */}
        <NavLink to="/Login">
          <Button>Logout</Button>
        </NavLink>
      </div>
    </header>
  );
};

export default Headers;
