import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "./loggedOUTheader";
//import "./loginPage.css";
import './Global.css';

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedInUser1, setLoggedInUser1] = useState(false);
  const [isLoggedInUser2, setLoggedInUser2] = useState(false);
  const navigate = useNavigate();
  
  const handleLogin = async () => {
    try {
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email || !emailRegex.test(email)) {
        alert("Please enter a valid email address");
        return;
      }
  
      // Check if password is empty
      if (!password) {
        alert("Please enter your password");
        return;
      }
  
      const response = await fetch("https://wdm.gxy5341.uta.cloud/laravel/public/api/logingraduate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.status === 1) {
          const { role } = data.user;
          const { id } = data.user;
          const { email, firstName, lastName, utaID } = data.user;
          localStorage.setItem('firstName', firstName);
          localStorage.setItem('id', id);
          localStorage.setItem('role', role);
          localStorage.setItem('lastName', lastName);
          localStorage.setItem('email', email);
          localStorage.setItem('utaID', utaID);
          if (role === "professor") {
            navigate("/professor-dashboard");
          } else if (role === "Student") {
            navigate("/student-dashboard");
          }
        } else {
          alert('Invalid Email and Password');
        }
      } else {
        alert('Invalid Email and Password');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <Header />
      <div className="login-container">
        <div className="login-content">
          <h2>Login</h2>
          <div className="p-field">
            <label htmlFor="username">EMail</label>
            <InputText
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="password">Password</label>
            <InputText
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button className="btn" label="Login" onClick={handleLogin} />
        </div>
        <footer className="footer">
          <p>&copy; 2024 Grad Impact</p>
        </footer>
      </div>
    </div>
  );
};

export default LoginPage;
