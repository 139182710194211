import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Modal, Rate, Select, Table } from 'antd';
import React, { useState,useEffect } from 'react';
import './Global.css';
import Header from './loggedINheader';
import 'bootstrap/dist/css/bootstrap.min.css';

const { Option } = Select;

const ProfessorDashboard = () => {
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchtasks();
    fetchreports();
  }, []);

  const [taskVisible, setTaskVisible] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [createTaskVisible, setCreateTaskVisible] = useState(false);
  
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [reportDetails, setReportDetails] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    startDate: '',
    deadline: '',
    priority: 'high', // Default priority
    professor_id:localStorage.getItem('id'),
    graduate_id:selectedStudent?selectedStudent:selectedStudent
  });
  
  const handleCreateTask = async () => {
    try {
      console.log(selectedStudent);
      const formDataWithIds = {
        ...formData,
        // deadline: localStorage.getItem('id'),
        graduate_id: selectedStudent
      };
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/addgraduateTask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formDataWithIds)
      });
      if (!response.ok) {
        throw new Error('Failed to create task');
      }
      setFormData({
        title: '',
        description: '',
        startDate: '',
        deadline: '',
        priority: 'high',
        priority: 'high', // Default priority
        deadline:localStorage.getItem('id'),
        graduate_id:selectedStudent
      });
      setCreateTaskVisible(false);
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const handleInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };
  const showTaskModal = (task) => {
    setSelectedTask(task);
    setTaskVisible(true);
  };

  const handleTaskOk = () => {
    setTaskVisible(false);
  };

  const handleTaskCancel = () => {
    setTaskVisible(false);
  };

  const showCreateTaskModal = () => {
    setCreateTaskVisible(true);
  };

  const handleCreateTaskOk = () => {
    setCreateTaskVisible(false);
  };

  const handleCreateTaskCancel = () => {
    setCreateTaskVisible(false);
  };

  const showReportModal = (report) => {
    setReportDetails(report);
    setReportVisible(true);
  };

  const handleReportOk = () => {
    setReportVisible(false);
  };

  const handleReportCancel = () => {
    setReportVisible(false);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleRatingChange = (value) => {
    setRating(value);
  };
  
  const fetchtasks = async () => 
  {
    
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getprofessortasks?professor_id='+localStorage.getItem('id'));
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setTasks(data.tasks);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }
  const fetchreports = async () => 
  {
    
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/reports');
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      // setReports(data.data);
      const convertedReports = data.data.map(dataItem => ({
        key: dataItem.id,
        title: dataItem.summary,
        description: dataItem.justification,
        hours: null, // Add the appropriate property value here, it's not provided in the report object
        submissionDate: dataItem.created_at.split(' ')[0], // Extracting only the date part
        aiDetection: dataItem.review ? dataItem.review.toString() + '%' : null, // Converting review to string and appending '%', handling null case
        reportlink: dataItem.attachment_path ? dataItem.attachment_path : '' // Adding a null check here
      }));
    
      setReports(convertedReports); // Set converted reports to the state
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/allUsers');
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const columns = [
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: 'Graduate',
      dataIndex: 'graduate',
      key: 'graduate',
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => showTaskModal(record)}>View</Button>
      ),
    },
  ];
  // const [reports, setReports] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [review, setReview] = useState('');

  const handleSubmit = async (event, reportId) => {
    event.preventDefault();
  
    try {
      const response = await fetch(`https://wdm.gxy5341.uta.cloud/laravel/public/api/feedbackReports`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback, review, id: reportId }),
      });
  
      if (!response.ok) {
        throw new Error('Feedback submission failed');
      }
  
      setFeedback('');
      setReview('');
      alert('Feedback and review submitted successfully');
    } catch (error) {
      console.error('Error submitting feedback and review:', error.message);
      alert('Feedback and review submission failed. Please try again.');
    }
  };
  
  // const data = [
  //   {
  //     key: '1',
  //     task: 'Task 1',
  //     status: 'Completed',
  //   },
  //   {
  //     key: '2',
  //     task: 'Task 2',
  //     status: 'In Progress',
  //   },
  // ];
  const convertedData = tasks.map(item => ({
    key: item.id.toString(),
    task: item.title,
    graduate:item.assigned_to_name,
    deadline:item.deadline,
    status: item.completed === 0 ? "In Progress" : "Completed"
  }));
  const TaskModal = ({ task, visible, onOk, onCancel }) => {
    return (
      <Modal
        title="Task Details"
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
      >
        {task && (
          <>
            <p>Task: {task.task}</p>
            {/* <p>Graduate: {task.assigned_to_name}</p> */}
            <p>Deadline: {task.deadline}</p>
            {/* <p>Status: {task.status}</p> */}
            {/* <p>Task: {task.task}</p> */}
            <p>Status: {task.status}</p>
          </>
        )}
      </Modal>
    );
  };

  const reportColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Hours Worked',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Submission Date',
      dataIndex: 'submissionDate',
      key: 'submissionDate',
    },
    {
      title: 'Report',
      dataIndex: 'reportlink',
      key: 'reportlink',
      render: (reportlink) => (
        reportlink ? <a href={'https://wdm.gxy5341.uta.cloud/laravel/'+reportlink}>View Report</a> : "Not Available"
      )
    },
    {
      title: 'AI Detection',
      dataIndex: 'aiDetection',
      key: 'aiDetection',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => showReportModal(record)}>View</Button>
      ),
    },
  ];

  const reportData = [
    {
      key: '1',
      title: 'Report 1',
      description: 'Description 1',
      hours: '5',
      submissionDate: '2022-03-01',
      aiDetection: '20%'
    },
    {
      key: '2',
      title: 'Report 2',
      description: 'Description 2',
      hours: '10',
      submissionDate: '2022-03-08',
      aiDetection: '32%'
    },
    {
        key: '3',
        title: 'Task 5',
        description: 'Create new data',
        hours: '20',
        submissionDate: '2022-03-08',
        aiDetection: '17%'
      }
  ];

  return (
    <div>
    <Header isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
    <div className='container'>
      <Card title="Student List">
      <Select
          style={{ width: '20%' }}
          onChange={(value) => setSelectedStudent(value)}>
          {users.map(user => (
            <Option key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</Option>
          ))}
        </Select>
        <Button onClick={showCreateTaskModal}>Create Task</Button>
      </Card>
      <TaskModal task={selectedTask} visible={taskVisible} onOk={handleTaskOk} onCancel={handleTaskCancel} />
      <Modal
  title="Report Details"
  visible={reportVisible}
  onOk={handleReportOk}
  onCancel={handleReportCancel}
>
  <p>Title: {reportDetails ? reportDetails.title : ''}</p>
  <p>Description: {reportDetails ? reportDetails.description : ''}</p>
  <p>Hours Worked: {reportDetails ? reportDetails.hours : ''}</p>
  <p>Submission Date: {reportDetails ? reportDetails.submissionDate : ''}</p>
  {console.log(reportDetails)}
  <p>Report: <a href={reportVisible && reportDetails.reportlink ? reportDetails.reportlink : '0'}>View Report</a></p>
  <p>Comments:</p>
  <Input.TextArea value={comment} onChange={handleCommentChange} />
  <p>Rating:</p>
  <Rate value={rating} onChange={handleRatingChange} />

  {/* New input fields for review and feedback */}
  <div>
    <p>Review:</p>
    <Input.TextArea value={review} onChange={(event) => setReview(event.target.value)} />
  </div>
  <div>
    <p>Feedback:</p>
    <Input.TextArea value={feedback} onChange={(event) => setFeedback(event.target.value)} />
  </div>

  {/* Button to submit feedback and review */}
  <Button type="primary" onClick={(event) => handleSubmit(event, reportDetails.key)}>Submit Feedback and Review</Button>
</Modal>

      <Card
        title={selectedStudent ? `Tasks - ${selectedStudent}` : ''}
        extra={
          selectedStudent ? (
            <Button>
              {/* <MailOutlined /> Generate Recommendation Letter */}
            </Button>
          ) : (
            ''
          )
        }
      >
        <Table columns={columns} dataSource={convertedData} />
      </Card>
      <Card title="All Weekly Reports" extra={<Button>Download All</Button>}>
        <Table columns={reportColumns} dataSource={reports} />
      </Card>
      <Modal
        title="Create New Task"
        visible={createTaskVisible}
        onOk={handleCreateTask}
        onCancel={() => setCreateTaskVisible(false)}
      >
        <Form>
          <Form.Item label="Title">
            <Input value={formData.title} onChange={e => handleInputChange('title', e.target.value)} />
          </Form.Item>
          <Form.Item label="Description">
            <Input.TextArea value={formData.description} onChange={e => handleInputChange('description', e.target.value)} />
          </Form.Item>
          <Form.Item label="Start Date">
            <Input type="date" value={formData.startDate} onChange={e => handleInputChange('startDate', e.target.value)} />
          </Form.Item>
          <Form.Item label="End Date">
            <Input type="date" value={formData.deadline} onChange={e => handleInputChange('deadline', e.target.value)} />
          </Form.Item>
          <Form.Item label="Priority">
            <Select value={formData.priority} onChange={value => handleInputChange('priority', value)}>
              <Option value="high">High</Option>
              <Option value="medium">Medium</Option>
              <Option value="low">Low</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      </div>
    </div>
  );
};

export default ProfessorDashboard;