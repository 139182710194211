import { Button } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import profilelogo from "../Designer.png";
//import "./header.css";
import "./Global.css";

const Header = ({ isLoggedIn }) => {
  return (
    <header>
      <div className="logo">
        <img src={profilelogo} alt="Logo" />
      </div>
      <h2 className="name h1-custom">Grad Impact</h2>
      <div className="actions">
        <NavLink to="/Home">
          <Button>Home</Button>
        </NavLink>
        <NavLink to="/Signup">
          <Button>Signup</Button>
        </NavLink>
        <NavLink to="/Login">
          <Button>Login</Button>
        </NavLink>
        <NavLink to="/Services">
          <Button>Services</Button>
        </NavLink>
        <NavLink to="https://wordpress.wdm.gxy5341.uta.cloud/">
          <Button>Blog</Button>
        </NavLink>
        <NavLink to="/ContactUs">
          <Button>Contact Us</Button>
        </NavLink>
        <NavLink to="/AboutUs">
          <Button>About Us</Button>
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
