import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import React, { useEffect,useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const user_id=        localStorage.getItem('id');
// useEffect({
  // fetch(getgraduatetasks)
  async function  fetchtasks()
  {
    
  const response = await fetch("https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduateupcommingtasks?grduate_id="+user_id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
      // if (response.ok) {
      //   const data = await response.json();
      
// },[]);
// Sample task data
// const tasks = [
//   { title: "Task 1", start: "2024-03-06", end: "2024-03-15" },
//   { title: "Task 2", start: "2024-03-13", end: "2024-03-16" },
//   { title: "Task 3", start: "2024-03-19", end: "2024-03-21" },
//   // Add more tasks as needed
// ];

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); 
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
      fetch('https://wdm.gxy5341.uta.cloud/laravel/public/api/getgraduateupcommingtasks?graduate_id='+localStorage.getItem('id'))
          .then(response => response.json())
          .then(data => {
            setTasks(data);
            console.log(data)
              const formattedEvents = data.flatMap(task => {
                  const createdDate = new Date(task.created_at);
                  const formattedDate = createdDate.toISOString().split('T')[0];
                  const startDateEvent = {
                      title: "Start Date: " + task.title,
                      start: formattedDate,
                      extendedProps: { id: task.id } 
                  };

                  const endDateEvent = {
                      title: "End Date: "+task.title,
                      start: task.deadline,
                      extendedProps: { id: task.id } 
                  };

                  return [startDateEvent, endDateEvent];
              });
              setEvents(formattedEvents);
          })
          .catch(error => console.error('Error fetching graduate tasks:', error));
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  return (
    <div className="container">
      <h2>Task List View</h2>
      <ul>
        {tasks.map((task, index) => (
          <li key={index} className="task">
            {task.title} - {formatDate(task.created_at)} to {task.deadline}
          </li>
        ))}
      </ul>

      <h2>Task Calendar View</h2>
      <div style={{ maxWidth: "900px", margin: "0 auto" }}>
        {/* <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={tasks}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          editable={false}
          eventLimit={true}
        /> */}
        <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        // eventClick={'handleEventClick'} 
                    />
      </div>
    </div>
  );
};

export default Calendar;
